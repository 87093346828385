<template>

<div>
    <b-card class="text-right">
    <a :href="'https://styles.untalk.io/tutorial/PDF/'+brand+'_manuale.pdf'" download>
      <b-button>Scarica la guida in PDF</b-button>
    </a>
  </b-card>
    <b-card>
    <h1>1. Attivazione Account</h1>
    
    <video class="video-player" :src="videoSrc+brand+'_video_tutorial_1.mp4'" controls></video>
  </b-card>
  <b-card>
    <h1>2. Primo Accesso,  Attivazione Segnalazione e Dati Aziendali</h1>
    
    <video class="video-player" :src="videoSrc+brand+'_video_tutorial_2.mp4'" controls></video>
  </b-card>
  <b-card>
    <h1>3. Segnalazione (Lato Utente)</h1>
    
    <video class="video-player" :src="videoSrc+brand+'_video_tutorial_3.mp4'" controls></video>
  </b-card>
  <b-card>
    <h1>4. Segnalazione (Amministratore)</h1>
    
    <video class="video-player" :src="videoSrc+brand+'_video_tutorial_4.mp4'" controls></video>
  </b-card>
  </div>
</template>

<script>


import {BCard,BButton} from 'bootstrap-vue'
export default {
  data() {
    return {
      videoSrc: "https://styles.untalk.io/tutorial/Video/",
      brand: window.location.hostname,
    };
  },
  components: {
    BCard,
    BButton,
  },
};
</script>
<style>
.video-player {
  width: 60%; /* Set the desired width of the video player */
  height: auto; /* Set height to "auto" to maintain aspect ratio */
}
</style>